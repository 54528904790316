const plural = [
  'аэролодки',
  'проходимые аэролодки',
  'бюджетные аэролодки',
  'аэроглиссеры',
  'глиссеры',
  'aeroglissers',
  'aeroglisser',
];

const single = ['аэролодка', 'проходимая аэролодка', 'бюджетная аэролодка', 'аэроглиссер', 'глиссер', 'aeroglisser'];

export { plural, single };
